import React from "react"
import promo from "../../src/video/rf-dance-bachata.mp4"
import Hero from "../components/hero"
import Contact from "../components/contact"
import Layout from '../components/layout-default'
import { Link } from 'gatsby'
import '../styles/layout.scss'

import TeamImg from "../images/team.jpeg"

const BachataMondays = () => (
  <Layout
    title="Bachata Classes in Orange County | RF Dance"
    description="OC's Homegrown latin dance classes with Rhythmic Fusion. $15 for a single class, or $65 for a 5 class bundle." 
    pathname="bachata-mondays"
    img={TeamImg}>
    <Hero 
      titleRed="RF"
      titleWhite="Classes"
      subtitle="Orange County's Weekly Latin Dance Classes"
      promo={promo}
      secondaryBtnTxt="Sign up online"
      secondaryBtnLink="/classes"
      secondaryBtnType="link" />
      
    <div className="content-block">
      <div className="container">
        <div className="tiles">
          <div className="tile">
            <Link to="/bachata-mondays">
              <h4 className="title">Monday</h4>
              <h5 className="subtitle">Bachata class</h5>
              <p className="details">Starts at 8pm</p>
            </Link>
          </div>
          <div className="tile">
            <Link to="/bachata-mondays">
              <h4 className="title">Tuesday</h4>
              <h5 className="subtitle">Bachata class</h5>
              <p className="details">Starts at 8pm</p>
            </Link>
          </div>
          <div className="tile">
            <Link to="/salsa-wednesdays">
              <h4 className="title">Wednesday</h4>
              <h5 className="subtitle">Salsa class</h5>
              <p className="details">Starts at 8pm</p>
            </Link>
          </div>
          <div className="tile">
            <h4 className="title">Thursday</h4>
            <h5 className="subtitle">Cumbia class</h5>
            <p className="details">Starts at 8pm</p>
          </div>
          <div className="tile">
            <Link to="/workshops">
              <h4 className="title">Saturday</h4>
              <h5 className="subtitle">Workshops</h5>
              <p className="details">Times vary</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
    <Contact />
  </Layout>
)

export default BachataMondays